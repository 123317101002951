<template>
  <div
    hakka-player="youtube-container"
    :style="containerStyle"
  >
    <div v-if="!disabled" :style="containerStyle">
      <div :id="elementId"></div>
    </div>

    <v-img
      v-if="disabled"
      :src="disablePhotoUrl"
      height="100%"
      contain
    ></v-img>
  </div>
</template>

<script lang="babel" type="text/babel">
import uuidV4 from 'uuid/v4'
import playerMixin from '@/components/player/playerMixin.js'
export default {
  mixins: [playerMixin],
  data: () => ({
    hasPlay: false,
  }),
  mounted() {
    this.setup()
  },
  methods: {
    async setup() {
      if(this.disabled == true) {
        return
      }

      await this.$nextTick()
      if(window.YT) {
        this.$nextTick(() => {
          this.onYouTubeIframeAPIReady()
        })
        return
      }

      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
      this.$nextTick(() => {
        this.onYouTubeIframeAPIReady()
      })
    },
    onYouTubeIframeAPIReady() {
      if(!window.YT) {
        setTimeout(() => {
          this.onYouTubeIframeAPIReady()
        }, 500)
        return
      }
      window.YT.ready(()=> {
        this.player = new YT.Player(this.elementId, {
          height: '100%',
          width: '100%',
          videoId: this.youtubeUid,
          playerVars: {
            autoplay: this.autoplay === true ? 1 : 0,
            start: !!this.startTime ? this.startTime : undefined,
          },
          events: {
            'onStateChange': this.onPlayerStateChange
          },
        })
      })
    },
    setupGetCurrentTimeCallback(event) {
      if(typeof this.getCurrentTimeCallback === 'function') return
      this.getCurrentTimeCallback = () => event.target.getCurrentTime()
    },
    onPlayerStateChange(event) {
      this.setupGetCurrentTimeCallback(event)
      if(event.data == YT.PlayerState.PLAYING) {
        if(this.hasPlay === false) {
          this.hasPlay = true
          this.onVideoFirstPlay()
        }

        else {
          this.hasPlay = true
          this.onVideoPlay()
        }
      }

      else if(event.data == YT.PlayerState.BUFFERING) {

      }

      else if(event.data == YT.PlayerState.CUED) {

      }

      else if(event.data == YT.PlayerState.ENDED) {
        this.onVideoEnd()
      }

      else if(event.data == YT.PlayerState.PAUSED) {
        this.onVideoPause()
      }
    },
  },
  computed: {
    youtubeUid() {
      if(!this.video.youtube_url) return null
      return this.$helper.getYoutubeUid(this.video.youtube_url)
    },
    youtubePhotoUrl() {
      if(!this.youtubeUid) return null
      // return `https://img.youtube.com/vi/${this.youtubeUid}/hqdefault.jpg`
      return `https://img.youtube.com/vi/${this.youtubeUid}/default.jpg`
    },
    disablePhotoUrl() {
      // if(this.computedPhotoUrl) return this.computedPhotoUrl
      return require('@/assets/img/default/presetImg16-9.png')
    },
    computedPhotoUrl() {
      if(this.youtubePhotoUrl) return this.youtubePhotoUrl
      return this.photoUrl
    },
    elementId() {
      return `youtube-${uuidV4()}`
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
