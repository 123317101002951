<template>
  <div
    hakka-player="videojs-container"
    :style="containerStyle"
  >
    <video
      class="video-js"
      controls
      :width="width"
      :height="height"
    ></video>
  </div>
</template>

<script lang="babel" type="text/babel">
import { apibase } from 'config/apibase.json'
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import 'videojs-contrib-quality-levels';
import 'videojs-http-source-selector';
import playerMixin from '@/components/player/playerMixin.js'
export default {
  mixins: [playerMixin],
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.$nextTick()
      await this.$helper.delay(0.2)
      if(!this.m3u8) return
      this.initPlayer()
    },
    async initPlayer() {
      const $playerContainer = $(this.$el).find('.video-js')[0]
      const self = this
      videojs($playerContainer, this.playerOptions, async function onPlayerReady() {
        self.player = this
        await self.$nextTick()
        const qualityLevels = self.player.qualityLevels();
        const sourceSelector = self.player.httpSourceSelector();
        self.setupGetCurrentTimeCallback()
        self.onPlayerReady()
      })
    },
    setupGetCurrentTimeCallback(event) {
      if(typeof this.getCurrentTimeCallback === 'function') return
      this.getCurrentTimeCallback = () => this.player.currentTime()
    },
    async onPlayerReady() {
      this.player.src({
        src: this.m3u8,
        type: 'application/x-mpegURL',
      })
      if(this.disabled == true) {
        this.player.controls(false)
        return
      }
      // this.player.controlBar.progressControl.disable()
      // this.player.src([
      //   {
      //     src: 'http://3.221.206.213:1935/hakkavod_resolution/smil:hakka-teach.smil/playlist.m3u8',
      //     type: 'application/x-mpegURL',
      //     label: '360p',
      //   },
      //   {
      //     src: 'http://3.221.206.213:1935/hakkavod_resolution/smil:hakka-teach.smil/playlist.m3u8',
      //     type: 'application/x-mpegURL',
      //     label: '480p',
      //   },
      //   {
      //     src: 'http://3.221.206.213:1935/hakkavod_resolution/smil:hakka-teach.smil/playlist.m3u8',
      //     type: 'application/x-mpegURL',
      //     label: '1080p',
      //   },
      // ])
      if(this.startTime) {
        this.player.currentTime(this.startTime)
      }
      await this.$nextTick()
      this.setupEventListeners()
      await this.$helper.delay(3)
      if(this.autoplay) {
        this.player.autoplay('muted')
      }
    },
    /**
     * 參考: https://docs.videojs.com/player#event:
     */
    setupEventListeners() {
      this.player.on('firstplay', () => {
        this.onVideoFirstPlay(false)
      })

      this.player.on('play', () => {
        this.onVideoPlay()
      })


      this.player.on('pause', () => {
        this.onVideoPause()
      })

      this.player.on('ended', () => {
        this.onVideoEnd()
      })
    },
    destroyPlayer() {

    },
  },
  watch: {
    m3u8: {
      handler() {
        this.init()
      },
    },
  },
  computed: {
    m3u8() {
      if(!this.video) return null
      return this.video.m3u8
    },
    playerOptions() {
      return {
        controls: true,
        preload: 'auto',
        poster: this.photoUrl || require('@/assets/img/default/presetImg16-9.png'),
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          hls: {
            overrideNative: true,
          }
        },
        plugins: {
          httpSourceSelector: { default: 'auto' }
        },
      }
    },
  },
}
</script>

<style lang="sass" type="text/sass">
div[hakka-player="videojs-container"]
  .video-js .vjs-time-control
    display: block
  .video-js .vjs-remaining-time
    display: none
</style>
